import React from 'react';
import './Header.css';


const Header = () => {
    return (
        <div className="header-container">
            <header className="header-text">Birketech.com</header>
        </div>
    )
}

export default Header
